import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>SILVER FILLINGS</h2>
      <h3>What are they?</h3>
      <p>A filling replaces part of a tooth that has been lost because of decay or through accidental damage.</p>
      <p>"Silver" fillings are made of amalgam - a mixture of mercury with other metals, such as silver, tin and copper. Amalgam is soft at first, but quickly becomes very hard once placed in the tooth.</p>
      <h3>What will my dentist do?</h3>
      <p>Your dentist will:</p>
      <ul>
        <li>usually  numb the area around  the tooth with an injection- but some small fillings may not need an anaesthetic</li>
        <li>remove  any decay, together  with any old filling material,  using a small, high-speed drill</li>
        <li>remove  any weak part of the tooth which might break later</li>
        <li>shape the hole, so that the tooth will hold the filling in place</li>
        <li>sometimes use tiny pins and screws  to help hold the tooth and filling together  (for a larger filling)</li>
        <li>put a lining underneath the filling if it is very deep, to make the tooth less sensitive  to temperature changes</li>
        <li>press  soft amalgam tightly  into the hole - you might  hear a squeak as it goes in</li>
        <li>carve  the filling  to fit the tooth (the dentist  might put a thin metal band around the tooth when they are doing  this to keep the amalgam in place while  it is still soft)</li>
        <li>ask you to close your mouth gently on the filling to check that it is the right height and is comfortable</li>
        <li>polish the filling at the next visit, when it is completely hard.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>A filling rebuilds the tooth so you can chew.If decay is left unchecked, it can spread into the tooth, causing pain and infection. This could mean the tooth has to be root filled or even taken out.</p>
      <p>A filling can end toothache or prevent toothache developing.</p>
      <p>Silver fillings are very strong, so they are ideal for back teeth, where there is heavy wear from chewing. They do, however, have the cosmetic drawback of turning black over time. White filling materials are also available, but maybe less durable than silver fillings under the wear and tear of chewing.</p>
    </LightboxLayout>
  )
}

export default Page